<template>
    <SfSection
        v-if="filteredEntries && filteredEntries.length > 0"
        class="section--title-lg"
    >
        <template #title>
            {{ title || t('title') }}
        </template>

        <template #default>
            <SfDiscoverStream
                v-if="filteredEntries"
                :entries="filteredEntries"
                :global-entries="entry.streamGlobalEntries"
            />
        </template>
    </SfSection>
</template>

<script setup>
import SfSection from '~/patterns/organisms/section/section';
import SfDiscoverStream from '~/patterns/organisms/discover-stream/discover-stream';
import DiscoverEntriesQuery from '~/graphql/queries/discover/discoverEntries.graphql';

const props = defineProps({
    title: {
        type: String,
        default: null
    },

    entry: {
        type: Object,
        default: null
    }
});

const { entry } = toRefs(props);

const { t } = useI18n({ useScope: 'local' });

const { variables: defaultVariables } = useDefaultVariables();

const tagIds = computed(() => entry.value?.tags?.map(tag => tag?.id) ?? null);

const variables = computed(() => {
    return {
        ...defaultVariables.value,
        limit: 6,
        showInStream: true,
        tagIds: tagIds.value?.length ? tagIds.value : null
    };
});

const { data } = await useAsyncQuery({
    query: DiscoverEntriesQuery,
    variables
});

const entries = computed(() => data.value?.entries ?? []);
const filteredEntries = computed(() => entries.value?.filter(v => v?.id !== entry.value?.id) ?? []);
</script>

<i18n lang="json">
{
    "nl": {
        "title": "Ontdek ook"
    },
    "en": {
        "title": "Discover more"
    }
}
</i18n>
